<template>
  <div class="checkbox-wrapper">
    {{ demoLogs }}
    <input v-model="search_text" type="search" :placeholder="placeholder" @input="getSearchResult">
    <div v-if="results.length > 0">
      <div v-for="result in results" :class="{active: result.service_id === selected}">
        <div @click="saveResult(result)">{{ result.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      send_timeout: null,
      selected: undefined,
      results: [],
      search_text: '',
      demoLogs: '',
      placeholder: 'Пошук адреси...',
    }
  },
  methods: {
    async getSearchResult() {
      clearTimeout(this.send_timeout);
      this.send_timeout = setTimeout(async () => {
        const response = await this.axios.get('/api/search?q=' + this.search_text)
        this.results = response.data
        Notification.requestPermission().then((result) => {
          console.log(result, this.results);
        });
      }, 2000);
    },
    saveResult(place) {
      console.log(place);
      if (Notification?.permission === "granted") {
        this.demoLogs += `The site has permission to show notifications. Showing notifications.\n`;
        // If the user agreed to get notified
        // Let's try to send ten notifications
        let i = 0;
        // Using an interval cause some browsers (including Firefox) are blocking notifications if there are too much in a certain time.
        const interval = setInterval(() => {
          // Thanks to the tag, we should only see the "Hi no 9 from MDN." notification
          const n = new Notification(`Hi no ${i} from MDN.`, {
            tag: "soManyNotification",
          });
          if (i === 9) {
            clearInterval(interval);
          }
          i++;
        }, 200);
      } else if (Notification && Notification.permission !== "denied") {
        this.demoLogs += "Requesting notification permission.\n";
        // If the user hasn't told if they want to be notified or not
        // Note: because of Chrome, we are not sure the permission property
        // is set, therefore it's unsafe to check for the "default" value.
        Notification.requestPermission().then((status) => {
          // If the user said okay
          if (status === "granted") {
            this.demoLogs +=
                "User granted the permission. Sending notifications.\n";
            let i = 0;
            // Using an interval cause some browsers (including Firefox) are blocking notifications if there are too much in a certain time.
            const interval = setInterval(() => {
              // Thanks to the tag, we should only see the "Hi! 9" notification
              const n = new Notification(`Message no ${i} from MDN.`, {
                tag: "soManyNotification",
              });
              if (i === 9) {
                clearInterval(interval);
              }
              i++;
            }, 200);
          } else {
            // Otherwise, we can fallback to a regular modal alert
            this.demoLogs += `User denied the permission request.\n`;
          }
        });
      } else {
        // If the user refuses to get notified, we can fallback to a regular modal alert
        this.demoLogs += `The site does not have permission to show notifications.\n`;
      }
    }
  }
}
</script>

<style>
.active {
  background-color: green;
}
</style>