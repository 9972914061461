<template>
  <div id="app">
    <Search></Search>
  </div>
</template>

<script>
import search from 'packs/components/search-bar/search.vue'
export default {
  data: function () {
    return {
    }
  },
  components: {
    Search: search
  }
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
